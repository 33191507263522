.folder-modal {
	background-color: rgba(0, 0, 0, 0.3);
	overflow-y: auto;

	&__dialog {
		max-width: 672px;
		min-width: 361px;
		margin: 6.75rem auto;
	}

	&__content {
		position: relative;
		padding: 1.5rem;

		&__header {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;
		}

		&__share_all {
			display: flex;
			align-items: center;
			height: 30px;
		}

		&--highlighted {
			background-color: $gray-3;
			padding: 0.5rem;
			color: $brand-black;
		}
	}

	&__form {
		&__label {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			color: $dark-gray;
			&--medium {
				font-weight: $font-weight-medium;
			}
		}

		&__input {
			width: 100%;
			border: 1px solid #000000;
			box-sizing: border-box;
			border-radius: 4px;
			min-height: 32px;
		}

		&__textarea {
			min-height: 147px;
		}

		&__buttons {
			display: flex;
			justify-content: flex-end;
		}

		&__select {
			border: none;
		}
	}

	&__close {
		background-image: url(../../images/close.svg);
		background-repeat: no-repeat;
		background-color: transparent;
		height: 15px;
		width: 15px;
		border: none;
		position: absolute;
		top: 5%;
		right: 2%;
	}

	&__item {
		background-color: white;
		color: black;
		border: 1px solid black;
		box-sizing: border-box;
		border-radius: 36px;
		min-width: 65px;
		height: 28px;
		text-align: center;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		padding-top: 1px;
		cursor: pointer;
		width: auto;

		&--selected {
			background-color: black;
			color: white;
		}
	}
}
