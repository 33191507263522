.global-header {
	min-height: $header-height;
	margin-top: 1rem;
	text-align: center;
	display: flex;
	justify-content: space-between;

	a {
		color: $white;
		&:hover {
			text-decoration: none;
			cursor: pointer;
		}
	}

	&__text {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 900;
		font-size: 16px;
		line-height: 19px;
		margin-left: 1.5rem;
		color: $white;
		text-align: left;
		padding-right: 0;
	}

	&__search {
		color: $white;
		display: flex;
		align-self: center;
		padding-left: 0;
		margin-left: -1rem;
	}

	&__playlist_context {
		display: flex;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	&__icons {
		align-self: center;
		display: flex;
		justify-content: flex-end;
		margin-left: -1.5rem;
	}

	&__user-actions {
		background-color: #c4c4c4;
		height: 35px;
		width: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-weight: 900;
		font-size: 0.8rem;
		cursor: pointer;
	}

	&__panel {
		visibility: hidden;
		min-width: 120px;
		background-color: white;
		color: black;
		position: absolute;
		z-index: 2;
		cursor: default;
		right: 0;
		top: 3rem;

		&--visible {
			visibility: visible;
		}
	}

	&__overlay {
		display: inline-block;
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: default;
	}

	&__user-action-item-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__user-action-item {
		padding: $padding-sm $padding-md;
		text-align: right;

		&:hover {
			background-color: $light-gray;
			cursor: pointer;
		}
	}
}
