.artist {
	&__card {
		& a {
			text-decoration: none;
			color: $brand-black;
			display: flex;
			min-height: 110px;
			align-items: center;
			width: 100%;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		min-height: 110px;
		display: flex;
		align-items: center;
		max-width: 100%;
		margin-right: 2rem;

		&--clickable {
			cursor: pointer;

			&:hover {
				background-color: $light-gray;
			}
		}

		&__image {
			background: #c4c4c4;
			height: 80px;
			width: 80px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			&__initials {
				font-size: 20px;
			}
		}

		&__metadata {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 1rem;
			margin-right: 1rem;
			line-height: 1.25;
			font-family: Roboto, sans-serif;
			width: calc(100% - 81px - 20px - 1rem - 1rem);
			word-break: break-word;

			> div:first-child {
				margin-bottom: 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
			}

			&--description {
				color: rgba(0, 0, 0, 0.6);
			}
		}

		&__blacklist {
			display: flex;
			margin-right: 0.75rem;
			& svg {
				fill: currentColor;
				width: 1rem;
				height: 1rem;
			}
		}
	}
}
