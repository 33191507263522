.text-input {
	display: flex;
	align-items: center;

	&__field {
		margin-bottom: 0;
		margin-right: 1rem;
	}

	&__button {
		margin-top: 0;
	}
}
