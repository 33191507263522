.status-badge {
	align-self: flex-start;
	font-size: 1rem;
	font-weight: normal;
	border-radius: 1rem;
	height: 37px;
	min-width: 65px;
	width: fit-content;
	padding: 6px 14px;
	background: $brand-yellow;
	color: black;

	&__text {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__alert {
		margin-left: 7px;
	}

	&__airtable {
		width: 25px;
	}

	&--collection-card {
		font-size: 0.75rem;
		font-weight: normal;
		border-radius: 0;
		width: fit-content;
		padding: 4px;
		font-weight: 800;
		text-transform: uppercase;
		height: fit-content;
		margin-left: 1.5rem;
		margin-top: 1.5rem;

		& .status-badge__airtable {
			width: 20px;
		}
	}
}
