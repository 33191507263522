.sidenav {
	margin-left: -15px;
	margin-right: -15px;
	height: 100%;

	&__user {
		text-align: center;
		padding-top: 2.5rem;
		min-height: 170px;
		padding-bottom: 1rem;

		&__role {
			margin-top: -8px;
			color: $white;
		}

		&__permission {
			margin-top: 8px;
		}

		&__badge {
			color: $brand-black;
			font-weight: $font-weight-medium;
			background-color: $brand-yellow;
			border-radius: 36px;
			padding: 2px 12px;
		}
	}

	&__drop-zone {
		&--dragging-over {
			background-color: $gray-4;
			padding-bottom: 1em;
		}

		&__placeholder {
			border-top: 1px $gray-5 dashed;
			border: dashed 2px $gray-5;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 1em;
			color: $white;
			min-height: 45px;
		}
	}

	&__content {
		@media only screen and (min-width: 992px) {
			height: calc(100% - 180px - #{$header-height});
			overflow-y: auto;
		}
	}

	&__actions {
		& .sidenav__item:first-child {
			border-top: 1px solid $gray-4 !important;
		}
	}

	&__item {
		display: flex;
		gap: 1rem;

		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 22px;
		height: 60px;
		padding-left: 2rem;
		padding-right: 2rem;
		cursor: pointer;
		text-decoration: none !important;
		color: $white;
		align-items: center;

		&:hover {
			color: $white;
			&:not(.sidenav__item--drop-disabled) {
				background-color: $gray-4;
			}
		}

		&--selected {
			background-color: $gray-5;
		}

		&__new {
			height: 75px;
		}

		img {
			margin-right: 1rem;
			min-width: 23px;
			justify-self: center;
		}
	}

	&--playing {
		.sidenav__content {
			@media only screen and (min-width: 992px) {
				height: calc(100% - 180px - #{$header-height} - 80px);
			}
		}
	}
}
