.toast {
	transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

	&__container {
		position: fixed;
		z-index: 6;
		right: 2em;
		margin-top: 3.5rem;
		min-width: 250px;
	}

	&--visible {
		visibility: visible;
		opacity: 100;
	}

	&--hidden {
		visibility: hidden;
		opacity: 0;
	}

	&--error {
		color: #721c24;
		background-color: #f8d7da;
		border-color: #f5c6cb;
	}

	&--success {
		color: #155724;
		background-color: #d4edda;
		border-color: #c3e6cb;
	}

	&--warning {
		color: $brand-black;
		background-color: #fff8cc;
		border-color: $brand-black;
	}

	&__close {
		cursor: pointer;
	}
}
