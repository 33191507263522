.genre-manager {
	&__dropdown {
		position: absolute;
		bottom: 0;
		left: 2.5rem;
		max-height: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow-y: auto;
		font-size: 1rem !important;
	}

	&__mappings {
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.85rem;
			div {
				align-items: center;
			}
		}
	}
	&__switch {
		&__label {
			margin-right: 10px;
			margin-top: 2px;
		}
	}

	&__cleared-list {
		&__table {
			& .data-table__row {
				border: none;
				height: 35px;
			}

			& .data-table__footer-row {
				border-bottom: none;
				border-top: 1px solid $brand-black;
			}
		}

		&__data-cell {
			display: flex;
			justify-content: space-between;
		}

		&__btn-icon {
			width: 1rem;
			height: 1rem;
			cursor: pointer;
		}

		&__table-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			& button {
				height: 40px;
				min-width: 90px !important;
			}
		}

		&__input {
			outline: none;
			border: none;
			border-bottom: 1px solid $gray-5;
			width: 25%;
			min-width: 250px;
		}
	}
}
