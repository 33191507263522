.content-copy-container {
	.content-copy-btn {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s ease, transform 0.2s ease;
		transform: translate(-4px, 0px);

		:hover > & {
			visibility: visible;
			opacity: 100;
			transform: translate(4px, 0);
		}
	}
}
