.more {
	margin: 0 $margin-sm;

	&__panel {
		visibility: hidden;
		min-width: 220px;
		background-color: white;
		color: black;
		position: absolute;
		z-index: 2;
		cursor: default;
		max-height: 350px;
		overflow: auto;
		margin-top: 0.5rem;

		&--visible {
			visibility: visible;
		}
	}
}
