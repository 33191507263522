.blacklist {
	.react-dual-listbox {
		height: 500px;
	}
	&__table {
		font-size: 0.85rem;

		&__artists {
			.data-table__cell {
				&:nth-child(1) {
					text-align: justify;
				}
			}
		}

		.data-table__header-cell {
			font-size: 1rem;
			font-weight: $font-weight-bold;
			text-align: center;
		}

		.data-table__cell {
			padding: 15px 2px;
			text-align: center;

			.blacklist__song-metadata {
				text-align: justify;
				margin-left: 25px;
			}

			.blacklist__playlist-metadata {
				text-align: left;
				margin-left: 25px;
			}

			.notes__tooltip {
				text-align: justify;
				display: flex;
				margin-top: 1.25rem;
				font-size: 0.85rem;
			}

			.notes__textarea {
				display: flex;
				font-size: 0.85rem;
			}
		}
	}

	&__btn-remove {
		display: flex;
		width: 1rem;
		height: 1rem;
		cursor: pointer;

		svg {
			width: 18px;
			height: 18px;
		}
	}

	&__modal {
		&__custom-content {
			&--highlighted {
				background-color: $gray-3;
				padding: 0.5rem;
				color: $brand-black;
			}
		}
	}
}
