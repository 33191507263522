.notes {
	&__image {
		cursor: pointer;
	}

	&__textarea {
		border: none;
		padding: 0;
		width: 95%;
		height: 32px;
		outline: none;
		resize: none;
		background: none;
	}

	&__paragraph {
		border: none;
		padding: 0;
		width: 95%;
		height: 32px;
		outline: none;
		background: none;
		max-height: 350px;
		overflow: auto;
		height: fit-content;
		display: flex;
		width: 100%;
		text-align: start;
		white-space: pre-wrap;
		word-break: break-word;
		margin: 0;
	}

	&__tooltip {
		position: relative;
		display: inline-block;
		width: 100%;

		&__panel {
			visibility: hidden;
			min-width: 319px;
			padding: 1rem;
			background-color: white;
			color: black;
			position: absolute;
			z-index: 2;
			bottom: 0;
			right: 1rem;
			cursor: default;
			height: 350px;

			&__notes {
				height: 212px;
				overflow-y: auto;
			}

			&__textarea {
				background: #ffffff;
				border: 1px solid #757575;
				box-sizing: border-box;
				border-radius: 2px;
				width: 100%;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				max-height: 200px;
				height: 100%;
			}

			&__add {
				height: 40px;
				min-width: 80px;
				font-family: Roboto, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 16px;
				background-color: $gray-2;
				letter-spacing: 0.02em;
				color: black;
			}

			&__close {
				font-family: Roboto, sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.02em;
				color: #000000;
				cursor: pointer;
			}

			&--visible {
				visibility: visible;
			}
		}
	}
}
