.navigation-tabs {
	border-bottom: solid 3px $brand-black;
	display: table;
	margin-bottom: 25px;
	width: 100%;

	> a {
		border-right: 2px solid $white;
		color: $black;
		cursor: pointer;
		display: table-cell;
		font-weight: bold;
		max-width: 100px;
		overflow: hidden;
		padding: 10px;
		position: relative;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:last-child {
			border-right: 0;
		}

		&:hover,
		&.active {
			background-color: $brand-black;
			color: $white;
			text-decoration: none;
		}
	}
}

.nav-tabs {
	border-bottom: 0;
}
