.health-dashboard {
	&__sanity-checks {
		&__row {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin-left: -1.5rem;
			margin: 1.75rem 0 0.5rem;
			padding: 1.5rem;
			background-color: $gray-3;

			.sanity-check {
				display: flex;
				padding: 0 1.5rem;
				margin: 1.5rem 0;
				width: 25%;
				border-left: 2px solid $gray-5;
				flex-direction: column;
				align-items: center;
				text-align: center;
				justify-content: center;

				&:first-child,
				&:nth-child(5) {
					border-left: none;
				}

				& .error {
					color: $rusty-red;
				}
			}
		}

		& .sanity-check {
			&__number {
				font-weight: $font-weight-bold;
				font-size: 1.5rem;
			}

			&__text {
				font-weight: $font-weight-medium;
				word-break: break-word;
				word-wrap: break-word;
				color: $dark-gray;
			}
		}
	}

	&__scheduled-jobs {
		&__table {
			.data-table__header-cell {
				font-size: 0.9rem;
				font-weight: $font-weight-bold;
				padding: 0 15px;
			}

			.data-table__cell {
				padding: 5px 15px;
			}
		}

		& .scheduled-job {
			&__action-btn {
				font-size: 12px;
				height: 28px;
				min-width: 100px;
				margin: 0.5rem 0;
			}

			&__status {
				font-weight: 700;
			}

			&--failed {
				color: $status-red;

				svg {
					fill: currentColor;
				}
			}

			&--running {
				color: $status-yellow;

				svg {
					fill: currentColor;
				}
			}

			&--completed {
				color: $status-green;

				svg {
					fill: currentColor;
				}
			}

			&--starting {
				color: $brand-orange;

				svg {
					fill: currentColor;
				}
			}
		}
	}

	&__alert {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		background-color: $gray-3;
		color: $status-red;
		font-weight: 700;
	}

	&__action-card {
		display: flex;
		padding: 1.25rem 1.5rem;
		margin: 1.5rem 0;
		margin-right: 1.5rem;
		width: 31%;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: space-between;
		background-color: $gray-6;

		&__container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin-left: -1.5rem;
			margin: 1.75rem 0 0.5rem;
			width: 100%;
		}

		&__title {
			font-size: 1.5rem;
			font-weight: 700;
		}
		&__description {
			margin: 0.5rem 0;
			text-align: justify;
		}
		&__disclaimer {
			font-size: 0.75rem;
		}
	}
}
