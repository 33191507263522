.playlist-rail {
	position: fixed;
	height: 100%;
	right: 0;
	width: 17%;
	background: #0d0d0f;
	color: white;
	font-family: Roboto, sans-serif;
	padding-top: 8rem;
	padding-left: 2rem;
	padding-right: 2rem;
	font-size: 0.9rem;

	&__header {
		font-size: 1.3rem;
		margin-bottom: 0.5rem;
	}

	&__playlists {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		overflow-y: auto;
		max-height: 80%;
		margin-left: -2rem;
		margin-right: -2rem;

		& .collection__card {
			width: 100%;
			padding-left: 2rem;
			padding-right: 2rem;
			padding-bottom: 1rem;
		}
	}

	&__new-playlist {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&--playing {
		@media only screen and (min-width: 992px) {
			height: calc(100% - 80px);
		}
	}
	.playlist {
		&__drop-zone {
			&--dragging-over {
				background-color: $gray-4;
				margin-left: 1px;
				& .collection__card {
					padding-bottom: 0;
				}
				padding-bottom: 0.5rem;
			}

			&__placeholder {
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				margin-bottom: 1rem;
				font-weight: $font-weight-bold;
				border: dashed 2px $gray-5;
				min-height: 45px;
				margin: 0 1em;
			}
		}
	}
}
