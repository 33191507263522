.player {
	background-color: $brand-black;
	position: fixed;
	height: $player-height;
	width: 100%;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 1rem 2rem 1rem 4.75rem;
	color: $white;
	z-index: 1030; // should be more than .sticky-top from bootstrap that is 1020

	&__cover {
		width: 56px;
		height: 56px;
		background-color: $gray-2;
		padding: 0 28px;
		animation: increase 0.5s linear 10ms infinite;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.5s ease-in-out;
	}

	&__track-info {
		padding: 0.375rem 1rem 0.375rem 0rem;
		display: flex;
		flex-direction: column;
		white-space: nowrap;
		overflow: hidden;

		&:hover {
			.player__track-info--overflowing {
				overflow: visible;
				animation: marquee 10s linear infinite;
				animation-play-state: running;
			}
		}

		&__name {
			font-weight: $font-weight-bold;
			font-size: 1.25rem;
			line-height: 23px;
			position: relative;
		}

		&__artists {
			font-size: 1rem;
			line-height: 20px;
			position: relative;
		}
	}

	&__progress-bar {
		display: flex;
		justify-content: center;
		align-items: baseline;
		width: 100%;
		font-size: 0.625rem;
		line-height: 0.75rem;
	}

	&__scrub {
		width: 80%;
		margin: 1rem;
		height: $player-scrub-height;
		color: $white;
		background-color: $gray-4;
		position: relative;
		cursor: pointer;

		&-progress {
			content: '';
			display: block;
			height: 100%;
			border-radius: inherit;
			background-color: $white;
			-webkit-transition: width 0.1s;
			-moz-transition: width 0.1s;
			transition: width 0.1s;
			-webkit-transition-timing-function: cubic-bezier(1, 0, 0.7, 1);
			-moz-transition-timing-function: cubic-bezier(1, 0, 0.7, 1);
			transition-timing-function: cubic-bezier(1, 0, 0.7, 1);
		}

		&-dot {
			content: '';
			width: 12px;
			height: 12px;
			position: absolute;
			background-color: $gray-5;
			border-radius: 25%;
			top: -2px;
			left: 0;
			margin-left: -0.25rem;
			visibility: hidden;
			cursor: pointer;
			&--visible {
				visibility: visible;
			}
		}

		&:hover {
			& .player__scrub-dot {
				visibility: visible;
			}
		}
	}

	&__btn {
		border: 0;
		padding: 0;
		background-color: $brand-black;

		&:focus {
			outline: none;
		}

		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	&__back-btn {
		margin-right: $margin-md;
	}

	&__forward-btn {
		margin-left: $margin-md;
	}

	&__playlist,
	&__song-details {
		font-weight: $font-weight-medium;
		font-size: 1rem;
		line-height: 20px;
	}
}
@keyframes marquee {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(-125%);
	}
	50.0001% {
		transform: translateX(125%);
	}
	100% {
		transform: translateX(0%);
	}
}
