.top-result {
	display: flex;
	align-items: center;
	padding: 1.5rem 1.25rem;
	margin-right: 2rem;
	min-height: 140px;
	width: 354px;
	border: 1px solid black;

	& a {
		color: inherit;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		&:hover {
			text-decoration: none;
		}
	}

	&--clickable {
		cursor: pointer;

		&:hover {
			background-color: $light-gray;
		}
	}

	&__image {
		&--img {
			height: 80px;
			width: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #979494;
		}

		&--rounded {
			border-radius: 50%;
		}

		&--default {
			height: 80px;
			width: 80px;
			background: #979494;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__placeholder {
			font-size: 20px;
			align-self: center;
			justify-content: center;
		}
	}

	&__metadata {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 1rem;
		font-family: Roboto, sans-serif;
		width: calc(100% - 80px - 1rem);

		&--title {
			color: #000000;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&--subtitle {
			color: rgba(0, 0, 0, 0.5);
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&__explicit {
			color: red;
			border: 1px solid red;
			padding: 0 2px;
			font-weight: $font-weight-medium;
			font-size: 0.5rem;
			opacity: 0.8;
			line-height: 0.75rem;
			align-self: flex-start;
		}
	}
}
