.alert-modal {
	background-color: rgba(0, 0, 0, 0.3);
	align-items: center;
	justify-content: center;

	&__content {
		position: relative;
		padding: 1.5rem;

		&__header {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;
		}
	}

	&__dialog {
		max-width: 672px;
		min-width: 361px;
		margin: 20% auto 25% auto;
		cursor: default;
	}

	&__reason {
		color: $dark-gray;
	}

	&__html-reasons {
		max-height: 250px;
		overflow: auto;

		&--link {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
