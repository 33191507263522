.playlists {
	&__header {
		border-bottom: 1px black solid;
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		padding-bottom: 6px;
		position: relative;

		&__see-all {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			position: absolute;
			bottom: 6px;
			right: 0;
			cursor: pointer;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding-top: 1rem;
	}

	&__filter {
		&__text {
			&--gray {
				color: $dark-gray;
			}
		}
	}
}

.playlist {
	&__card {
		min-height: 300px;
		min-width: 300px;
		width: 23.5%;
		margin-bottom: 1rem;
		margin-right: 1rem;
		background-size: cover;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		&:hover {
			cursor: pointer;
			opacity: 0.95;
		}

		&__details {
			color: white;
			padding-bottom: 1rem;
			margin-left: 1.5rem;

			&__title {
				font-family: Roboto, sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 32px;
				margin-bottom: -2px;
			}

			&__difficulty {
				margin-top: 0.4rem;
			}

			&__time {
				float: right;
				margin-right: 1rem;
			}

			&__notes {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-right: 1rem;
				margin-top: 0.3rem;
			}
		}
	}
}
