.user-modal {
	background-color: rgba(0, 0, 0, 0.3);

	&__content {
		padding: $padding-md;
	}

	&__dialog {
		max-width: 672px;
		margin: 5% auto 25% auto;
		max-height: 80%;
		min-height: 20%;
		overflow-y: auto;
	}

	&__submit-btn {
		margin: $margin-sm;
	}

	&__cancel-btn {
		margin: $margin-sm 0 $margin-sm $margin-sm;
	}

	&__title {
		font-size: 24px;
		margin-bottom: $margin-md;
	}

	&__label {
		font-size: 16px;
		color: $dark-gray;
		text-align: left;
	}

	&__input {
		border: 1px solid #000000;
		border-radius: 4px;
		min-height: 32px;
		margin-bottom: 1rem;
	}

	&__roles-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		& label {
			display: flex;
			align-items: center;
			&:not(:last-child) {
				margin-right: 1rem;
			}
			& input[type='checkbox'] {
				margin-right: 0.5rem;
			}
		}
	}

	&__permissions-container {
		& label {
			display: flex;
			align-items: center;
			& input[type='checkbox'] {
				margin-right: 0.5rem;
			}
		}
		& p {
			font-weight: $font-weight-light;
			font-size: $base-font-size;
		}
	}

	& .disabled {
		cursor: not-allowed;
		opacity: 0.75;
	}

	&__error {
		color: red;
	}

	& .error {
		border-color: red;
	}

	&__select {
		height: 32px;
		background: white;
	}

	::placeholder {
		color: black;
	}

	&__brands {
		margin-bottom: 1rem;
	}
}
