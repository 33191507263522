.browse {
	&__back {
		font-size: 0.875rem;
		max-width: max-content;
		& svg {
			margin-right: $margin-md;
		}

		&:hover {
			text-decoration: underline;
			cursor: pointer;
			font-weight: $font-weight-bold;
		}
	}

	&__header {
		font-weight: 300;
		font-size: 40;
		&__subtitle {
			opacity: 0.3;
			font-size: 1.5rem;
			margin-left: 1rem;
		}
		display: flex;
		align-items: center;
	}

	&__actions {
		display: inline-block;
		width: 100%;
		padding: 1rem;
		background-color: $light-gray;
		&__collection-filter {
			max-width: 80%;
			float: left;
		}

		&__pagination {
			display: flex;
			align-items: center;
			float: right;
			justify-content: flex-end;
			min-width: 20%;
			font-size: 1.125rem;

			&__dropdown {
				border: 1px solid black;
				margin-left: 1rem;
				padding-left: 0.5rem;
				padding-right: 0.5rem;

				&__menu {
					right: 0;
					margin-top: 0.5rem;
					margin-right: -0.5rem;
					width: max-content;
				}
			}
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
	}

	&__cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-left: -1.5rem;
	}

	&__card {
		display: flex;
		align-items: center;
		margin-bottom: 2.75rem;
		margin-left: 1.5rem;
		width: 21%;
		text-decoration: none;
		color: $brand-black;
		word-break: break-word;

		&:hover {
			cursor: pointer;
			font-weight: $font-weight-bold;
			text-decoration: none;
			color: $brand-black;
		}

		&--text-only {
			width: 21.5%;
			min-height: 80px;
			border: 1px solid $brand-black;
			font-size: 1.5rem;
			justify-content: center;
			text-align: center;
			&:hover {
				border: 2px solid $brand-black;
			}
		}
	}
}
