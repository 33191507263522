body {
	margin: 0;
	font-family: Roboto, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.layout {
	height: 100vh;
	overflow-x: hidden;

	&--no-scroll {
		overflow: hidden;
	}
}

.side-nav {
	box-shadow: 0 0.225rem 1.2rem rgba(0, 0, 0, 0.075) !important;
	background: $brand-black;
	@media only screen and (min-width: 992px) {
		position: fixed;
		height: 100%;
	}
}

.main {
	padding-bottom: $player-height;
	padding-top: 1rem;
	padding-right: 2.5rem;
	padding-left: 2.5rem;

	&__header {
		display: flex;

		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 3rem;

		&--collapsed {
			margin-bottom: 0;
		}

		&__left {
			display: flex;
			width: 100%;
			align-items: center;
		}

		&__right {
			display: flex;
			align-items: center;
			position: relative;
		}
	}

	&__content {
		&--playing {
			margin-bottom: $player-height;
		}
	}

	&--playlist-rail-open {
		.main__content {
			width: 80%;
		}
		.main__header {
			&__right {
				position: fixed;
				z-index: 5;
				margin-top: 1rem;
				margin-right: 2.5rem;
				top: 0;
				right: 0;
			}
		}
	}
}
