.my-songs {
	.dropdown__menu.my-songs__song__dropdown__menu {
		position: absolute;
		bottom: 0;
		left: 2.5rem;
		margin-left: 0;
		width: max-content;
		margin-top: 0.25rem;
		margin-right: -0.5rem;
		max-height: 250px;
		overflow-y: scroll;
	}
	&__empty {
		margin-top: toRem(80px);

		&__container {
			max-width: 45%;
		}

		&__header {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 33px;
			text-align: center;
		}

		&__sub {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #757575;
			margin-top: 1rem;
		}
	}
}
