.catalog {
	&__stats__column {
		display: flex;
		background-color: $gray-3;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		.catalog__stat {
			align-items: center;
			text-align: center;
			justify-content: center;
			border-bottom: 2px solid $gray-5;
			padding-bottom: 1rem;
			padding-top: 1rem;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	&__stats__row {
		display: flex;
		margin-bottom: 2rem;
		background-color: $gray-3;
		.catalog__stat {
			width: 100%;
			margin: 1.5rem 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			justify-content: center;
			border-left: 2px solid $gray-5;

			&:first-child {
				border-left: none;
			}
		}
	}

	&__stat {
		&--focusable:hover {
			cursor: pointer;
			.catalog__stat__text,
			.catalog__stat__number {
				color: $brand-black;
			}
		}

		&__number {
			font-weight: $font-weight-bold;
			font-size: 1.5rem;
		}

		&__text {
			font-weight: $font-weight-medium;
			word-break: break-word;
			word-wrap: break-word;
			color: $dark-gray;
		}
	}

	&__container {
		margin: 0 0 2rem 0;
		&--sidebar {
			> div {
				border-left: 2px solid $gray-5;
			}
		}
	}

	&__chart {
		margin-bottom: 1.25rem;
	}
}
