.edit-playlist-modal {
	background-color: rgba(0, 0, 0, 0.3);

	&__dialog {
		max-width: 672px;
		min-width: 361px;
		margin: 20% auto 25% auto;
	}

	&__submit-btn {
		margin: $margin-sm;
	}

	&__cancel-btn {
		margin: $margin-sm 0 $margin-sm $margin-sm;
	}

	&__content {
		padding: $padding-md;
	}

	&__title {
		font-size: 24px;
		margin-bottom: $margin-md;
	}

	&__label {
		font-size: 16px;
		color: $dark-gray;
	}

	&__input {
		border: 1px solid #000000;
		border-radius: 4px;
		min-height: 32px;
	}
}
