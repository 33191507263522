.dashboard {
	& .dashboard-tabs {
		display: flex;
		border: none;

		> a {
			text-decoration: none;
			color: rgba(0, 0, 0, 0.6);

			h3 {
				font-weight: 300;
				font-size: 2.5rem;

				&:last-child {
					padding-right: none;
				}
			}

			&:hover,
			&.active {
				color: $brand-black;
			}
		}
	}

	&__collection {
		&__playlist-card {
			&.playlist__card {
				display: flex;
				min-height: 300px;
				min-width: -webkit-fill-available;
				justify-content: flex-end;
			}
		}

		&__folder-card {
			&.folder__card {
				display: flex;
				min-height: 300px;
				min-width: -webkit-fill-available;
				justify-content: flex-end;
				padding-right: 0.5rem;
			}
		}
	}
}
