.playlist-grid {
	width: 100%;

	&__header-cell {
		font-size: 20px;
		line-height: 23px;
		text-align: left;
	}

	&__header-row {
		border-bottom: 1px solid black;
		height: 72px;
	}

	&__row {
		border-bottom: 1px solid black;
		height: 72px;

		td {
			padding-right: 2rem;
		}

		&:hover {
			background-color: $light-gray;
			color: $black;
		}
	}

	&__play {
		cursor: pointer;
		margin: 0 $margin-md;
	}
}
