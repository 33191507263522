.folder {
	&__container {
		margin-top: -2rem;
	}

	&__back {
		color: black;
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.02em;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}

		&__btn {
			margin-right: 10px;
			padding-bottom: 4px;
		}
	}

	&__header {
		margin-top: 1.1rem;
		display: flex;

		&__badge {
			margin-top: -1.8rem;
			margin-left: -0.5rem;
		}
	}

	&__sub-text {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 1rem;

		&--no-margin {
			margin-bottom: 0;
		}
	}

	&__target-duration {
		&__text {
			margin-left: 5px;
			margin-right: 5px;
		}

		&__arrow {
			margin-top: 7px !important;
		}

		&--invalid {
			margin-left: 0.5rem;
			color: red;
		}
	}

	&__description {
		margin-top: 1rem;
		max-width: 581px;
	}

	&__empty {
		margin-top: toRem(100px);

		&__container {
			max-width: 450px;
		}

		&__header {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 33px;
			text-align: center;
		}

		&__sub {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #757575;
			margin-top: 1rem;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	&__viewers_count {
		display: flex;
		align-items: center;
		margin: auto 2px;
		& img {
			margin-left: 10px;
			margin-right: 5px;
		}
	}

	&__switch {
		&__label {
			margin-right: 10px;
			margin-top: 2px;
		}
	}

	&__metadata {
		font-size: 0.95rem;
		&__value {
			font-weight: $font-weight-bold;
		}
	}

	&__share_btn {
		margin-top: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;

		& svg {
			margin-left: 0.5rem;
			fill: currentColor;
		}
	}
}
