.settings {
	&__add-new-email {
		width: 70%;
	}

	&__add-email-label {
		margin-right: 1rem;
		margin-bottom: 0;
	}

	&__add-email-input {
		width: 50%;
	}

	&__table {
		min-width: 690px;

		&-header-row {
			border-bottom: 1px solid black;
			height: 72px;
		}

		&-header-cell {
			font-size: 20px;
			line-height: 23px;
			text-align: left;
			padding: 1rem;
		}

		&-row {
			height: 30px;
		}
	}

	&__add-new-btn {
		margin: 0;
	}

	&__delete-btn {
		background-color: transparent;
		border: none;
		margin-left: 1rem;
	}
}
