// Base
@import 'variables';
@import 'functions';
@import 'layout';
@import 'typography';
@import 'buttons';

// Vendors
@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-dual-listbox/src/scss/react-dual-listbox';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// Customizations
@import './customizations/react-select';

// Pages
@import 'pages/login';
@import 'pages/playlists';
@import 'pages/team';
@import 'pages/catalog';
@import 'pages/genre-manager';
@import 'pages/song-validation';
@import 'pages/licesing-dashboard.scss';
@import 'pages/browse.scss';
@import 'pages/search.scss';
@import 'pages/health.scss';
@import 'pages/my-songs.scss';
@import 'pages/show-playlists.scss';
@import 'pages/settings.scss';

// Components
@import 'components/badge';
@import 'components/header';
@import 'components/content-copy';
@import 'components/tooltip';
@import './components/search-bar';
@import './pages/dashboard';
@import './components/playlists';
@import './components/playlist';
@import './components/songslist';
@import './components/sidenav';
@import './components/dropdown';
@import './components/songcards';
@import './components/new-playlist';
@import './components/notes';
@import './components/pagination';
@import './components/alert-modal';
@import './components/more-button';
@import './components/notifications';
@import './components/playlist-grid';
@import './components/player';
@import './components/edit-playlist-modal';
@import './components/team-grid';
@import './components/user-modal';
@import './components/toast';
@import './components/file-input';
@import './components/data-table';
@import './components/blacklist';
@import './components/artist-name-filter';
@import './components/folders';
@import './components/folder';
@import './components/folder-modal';
@import './components/navigation-tabs';
@import './components/loader';
@import './components/text-input-form';
@import './components/search/top-result';
@import './components/search/artist';
@import './components/search/song-result';
@import './components/search/collection';
@import './components/search/playlist-rail';
@import './components/search/song-table';
@import './components/search/action-menu';
@import './components/status-badge';

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.error {
	color: $light-red;
}
