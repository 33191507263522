.search {
	&__back {
		font-size: 0.875rem;
		max-width: max-content;
		& svg {
			margin-right: $margin-md;
		}

		&:hover {
			text-decoration: underline;
			cursor: pointer;
			font-weight: $font-weight-bold;
		}
	}
	&__filters {
		display: flex;
		align-items: flex-end;
		margin-bottom: 2rem;
		& span {
			font-size: 0.9rem;
		}
	}
	&__top-result {
		margin-bottom: 5rem;
	}
	&__header {
		&__subtitle {
			opacity: 0.3;
			font-size: 1.5rem;
			margin-left: 1rem;
		}
		display: flex;
		align-items: center;
	}

	&__loader {
		margin-top: 30vh;
	}

	&__empty {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;

		margin-top: toRem(100px);

		&__container {
			max-width: 450px;
		}

		&__header {
			font-weight: bold;
			font-size: 28px;
			line-height: 33px;
			text-align: center;
		}

		&__sub {
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #757575;
			margin-top: 1rem;
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
	}

	&__cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	&__card {
		display: flex;
		align-items: center;
		margin-bottom: 2.75rem;
		margin-right: 1.5rem;
		width: 30.5%;
		text-decoration: none;
		color: $brand-black;

		&:hover {
			cursor: pointer;
			font-weight: $font-weight-bold;
			text-decoration: none;
			color: $brand-black;
		}

		&--text-only {
			width: 22.5%;
			height: 80px;
			border: 1px solid $brand-black;
			font-size: 1.5rem;
			justify-content: center;
			text-align: center;
			&:hover {
				border: 2px solid $brand-black;
			}
		}
	}
}
