.file-input {
	&--block {
		& .file-input__wrapper {
			flex-direction: column;
		}
		& .file-input__name {
			margin-left: 0.25 rem;
		}
	}

	&__wrapper {
		display: flex;
		align-items: baseline;
	}

	&__input {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-top: 0;
		cursor: pointer;
	}

	&__name {
		margin-left: 0.5rem;
		color: $gray-5;
		font-size: $base-font-size;
		border-bottom: 1px solid $gray-5;
		padding: 0 5px;
	}

	&--disabled {
		cursor: default;
		opacity: 0.65;

		& .file-input__input {
			cursor: default;
		}
	}
}
