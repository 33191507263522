.login {
	&__container {
		background-color: black;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__form {
		max-width: 641px;

		&__input {
			display: block;
			height: calc(1.5em + 0.75rem + 2px);
			padding: 0.375rem 0.75rem;
			font-weight: bold;
			font-size: 20px;
			line-height: 1.5;
			background-clip: padding-box;
			color: white;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

			background: $black;
			border: 1px none #ced4da;
			border-bottom-style: solid;

			position: relative;
			flex: 1 1 auto;
			width: 1%;
			margin-bottom: 0;

			&__icon {
				display: flex;
				align-items: center;
				padding: 0.375rem 0.75rem;
				margin-bottom: 0;
				white-space: nowrap;
				background-color: black;
				border: 1px solid #ced4da;
				border-top: none;
				border-left: none;
				width: 40px;

				&--email {
					background-image: url(../../images/envelope.svg);
					background-position: 5px 12px;
					background-repeat: no-repeat;
					object-fit: cover;
				}

				&--password {
					background-image: url(../../images/lock.svg);
					background-position: 6px 2px;
					background-repeat: no-repeat;
					object-fit: cover;
				}
			}

			&::placeholder {
				color: white;
				font-size: 1.5rem;
				font-weight: 300;
			}
			&::-webkit-input-placeholder {
				/* WebKit, Blink, Edge */
				color: white;
			}
			&:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: white;
				opacity: 1;
			}
			&::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: white;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: white;
			}
			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: white;
			}
		}
	}

	&__forgot-password {
		color: white;
		font-weight: 500;
		cursor: pointer;
	}

	&__error {
		color: $light-red;
	}

	&__success {
		color: #008080;
	}
}
