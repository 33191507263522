.primary-btn {
	margin-top: 2rem;
	height: 50px;
	min-width: 131px;
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	background-color: $brand-black;
	border-radius: 1000px;
	color: $white;

	&:hover:not(:disabled) {
		color: $gray-5;
	}

	&:hover:disabled {
		color: $white;
	}

	&:disabled {
		opacity: 0.65;
	}

	&--w-guards {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.secondary-btn {
	margin-top: 2rem;
	height: 50px;
	min-width: 131px;
	border: 2px solid #000000;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.02em;
	background-color: white;
	border-radius: 1000px;

	&:hover:not(:disabled) {
		color: $brand-black;
	}

	&:hover:disabled {
		color: $brand-black;
	}

	&:disabled {
		color: $brand-black;
	}

	&:disabled {
		opacity: 0.65;
	}
}

button,
button:focus {
	outline: none;
}
