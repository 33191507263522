.song-result {
	display: flex;
	justify-content: space-between;
	margin-right: 2rem;

	& a {
		text-decoration: none;
		color: inherit;
		display: flex;
		height: 100%;
		display: flex;
		align-items: center;
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;

		&:hover {
			background-color: $light-gray;
		}
	}

	&__metadata {
		display: flex;
		overflow: hidden;
		padding-right: 0.5rem;

		&__image {
			&--img {
				height: 100px;
				width: 100px;
				cursor: pointer;
				background: #c4c4c4;
			}

			&--default {
				background: #c4c4c4;
				height: 100px;
				width: 100px;
			}
		}

		&__song {
			margin-left: 1rem;
			display: flex;
			flex-direction: column;
			font-family: Roboto, sans-serif;
			overflow: hidden;
			width: calc(100% - 100px - 1rem);

			&--name {
				font-style: normal;
				font-weight: normal;
				color: #000000;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: pointer;
			}

			&--secondary {
				color: rgba(0, 0, 0, 0.5);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__explicit {
				color: red;
				border: 1px solid red;
				padding: 0 2px;
				font-weight: $font-weight-medium;
				font-size: 0.5rem;
				opacity: 0.8;
				line-height: 0.75rem;
				align-self: flex-start;
			}
		}
	}

	&__actions {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 0.5rem;

		&__item {
			background: none;
			border: none;
		}
	}
}
