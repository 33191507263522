$search-button-width: 151px;

.search-bar {
	&__container {
		width: 80%;
		border: 2px solid rgba(0, 0, 0, 0.3);

		&--dropdown-open {
			border-bottom: 0;
			box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
		}
	}

	&__input-container {
		width: 100%;
	}

	&__input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-right: 1rem;
		margin-left: 1rem;

		&__btn {
			height: 32px;
			margin: 0;
			& svg {
				width: 18px;
				height: 18px;
			}
		}

		& input[type='search'] {
			width: calc(100% - #{$search-button-width});
			outline: 0;
			background: none;
			border: none;
			color: inherit;
			min-height: 35px;
			font-size: 1.125rem;
			line-height: 1.25rem;
			margin: 6px;

			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
				height: 10px;
				width: 10px;
				background: url(../../images/cancel-grey.svg) no-repeat center;
				cursor: pointer;
			}
		}

		&--dropdown-open {
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
			padding-bottom: 1px;
		}
	}

	&__suggestions {
		&__container {
			position: relative;
		}
		&__panel {
			position: absolute;
			border: 2px solid rgba(0, 0, 0, 0.3);
			border-top: 0;
			background: $white;
			top: 0;
			left: -2px;
			right: -2px;
			max-height: 0px;
			z-index: 2;
			transform: scaleY(0);
			transform-origin: top;
			overflow-y: hidden;
			padding: 0 1rem;
			-webkit-transition: transform 0.15s ease-in-out;
			-moz-transition: transform 0.15s ease-in-out;
			-o-transition: transform 0.15s ease-in-out;
			-ms-transition: transform 0.15s ease-in-out;
			transition: transform 0.15s ease-in-out;

			&--expanded {
				overflow-y: auto;
				max-height: 460px;
				padding: 0.5rem 1rem 0.25rem;
				transform: scaleY(1);
				box-shadow: 5px 0px 4px -4px rgba(0, 0, 0, 0.25);
			}
		}

		&__section {
			margin-bottom: 0.5rem;

			&__title {
				font-family: Roboto, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				line-height: 2rem;
				color: $black;
				opacity: 0.8;
				width: 50%;
				border-bottom: 1px solid rgba(0, 0, 0, 0.3);
				margin-bottom: 0.5rem;
			}

			ul {
				list-style-type: none;
				padding-inline-start: 0;
				margin: 0;
			}

			&__suggestion {
				font-family: Roboto, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 0.9rem;
				color: rgba(0, 0, 0, 0.4);
				margin: 0 -1rem;
				padding: 0.25rem 1rem;
				display: flex;
				align-items: center;

				&:hover {
					background-color: $gray-6;
					cursor: pointer;
				}

				& b {
					color: $brand-black;
				}

				&--explicit {
					color: red;
					border: 1px solid red;
					margin-left: 0.75rem;
					padding: 0 2px;
					font-weight: $font-weight-medium;
					font-size: 0.5rem;
				}
			}
		}
	}
}
