.h1 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 6rem; // ~96px
	line-height: 112px;
	/* identical to box height */

	display: flex;
	align-items: center;

	&--white {
		color: white;
	}
}

.h2 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 3.75rem; // ~60px
	line-height: 70px;
}

.h3 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 3rem; // ~48px
	line-height: 56px;
}

.h5 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 1.5rem; // ~24px
	line-height: 32px;
	/* identical to box height, or 133% */

	&--white {
		color: white;
	}
}

.h6 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 1.25rem; // ~20px
	line-height: 23px;

	&--white {
		color: white;
	}
}

.b1 {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
}
