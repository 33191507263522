// Fonts
$base-font-size: 14px;
$base-font-family: 'Avenir';
$base-font-weight: 400;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-heavy: 900;

// Colours
$black: #000000;
$brand-black: #0d0d0f;
$white: #ffffff;
$gray: #eaeaea;
$gray-2: #c9c9c9;
$gray-3: #f1f1f1;
$gray-4: #3f3e3e;
$gray-5: #8d8d8d;
$gray-6: rgba(196, 196, 196, 0.2);
$dark-gray: #757575;
$border-gray: #dbdbdb;
$dark-slate-blue: #1b3b58;
$navy: #051d33;
$light-gray: #fafafa;
$deep-turquoise: #00677f;
$rusty-red: #ab1212;
$bright-orange: #ff5500;
$light-violet: #d4d1dd;
$greenish-cyan: #50e3c2;
$light-cyan: #6dc7be;
$brand-yellow: #ffde01;
$light-red: #f08080;
$status-yellow: #ffcc00;
$status-green: #339900;
$status-red: #cc3300;
$brand-orange: #fd7e14;
// Margin
$margin-md: 20px;
$margin-sm: 10px;

// Padding
$padding-md: 20px;
$padding-sm: 10px;

$player-height: 88px;
$player-scrub-height: 8px;
$header-height: 50px;
