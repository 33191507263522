.dropdown {
	&__root {
		cursor: pointer;
		height: 2rem;
		display: flex;

		&--center {
			padding-top: 0.5rem;
		}

		&.Dropdown-disabled {
			cursor: default;

			.Dropdown-arrow-wrapper {
				visibility: hidden;
			}
		}
	}

	&__placeholder {
		font-size: 1rem;
		line-height: 19px;
		text-align: right;
	}

	&__filter {
		background-image: url(../../images/filter.svg);
		background-repeat: no-repeat;
		padding-left: 2.5rem;
	}

	&__arrow {
		background-image: url(../../images/arrow-down.svg);
		background-repeat: no-repeat;
		display: block;
		width: 12px;
		height: 8px;
		margin-left: 7px;
		margin-top: 4px;

		&--inverse {
			transform: rotate(180deg);
		}
	}

	&__menu {
		position: absolute;
		z-index: 10;
		background: white;
		margin-left: 2rem;
		width: 300px;
		border-radius: 0.25rem;
		margin-bottom: 0.5rem;
		padding-top: 8px;
		padding-bottom: 8px;

		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: inh;
		line-height: inh;

		&--left {
			right: 0;
		}

		.Dropdown-option {
			height: 50px;
			display: flex;
			align-items: center;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	&__sort {
		margin-left: -9rem;
	}
}

.Dropdown-root {
	position: relative;

	.Dropdown-control {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}

.Dropdown-option {
	cursor: pointer;
	&:hover {
		background-color: $gray-6;
		cursor: pointer;
	}
}
