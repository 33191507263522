.song-table {
	font-family: Roboto, sans-serif;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__title {
			display: flex;
			align-items: center;
			width: 60%;

			&__subtitle {
				color: rgba(0, 0, 0, 0.3);
				margin-bottom: 1px;
				font-size: 1rem;
			}
		}

		&__action-btn {
			display: flex;
			margin: 0 1rem 0 0;
			padding: 0 1.5rem;
			align-items: center;
			height: 2rem; // same as dropdown

			& svg {
				fill: currentColor;
				width: 20px;
				height: 16px;
				margin-right: 0.75rem;
			}
		}
	}

	&__filter {
		display: flex;
		align-items: center;

		&--wrappable {
			flex-wrap: wrap;
			margin-top: -1rem;
			align-items: flex-end;
		}

		&__dropdown {
			border: 1px solid black;
			margin-left: 1rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			min-width: 100px;

			&--large {
				min-width: 150px;
			}

			&__menu {
				right: 0;
				margin-top: 0.25rem;
				margin-right: -0.5rem;
				width: max-content;
				max-height: 500px;
				overflow-y: auto;
				min-width: 100px;
				&--large {
					min-width: 150px;
				}
			}
		}

		&__badge {
			background-color: $white;
			color: $brand-black;
			border: 1px solid black;
			margin: 1rem 0.5rem -0.1rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-radius: 0;

			&:hover {
				.badge__close {
					cursor: pointer;
					color: #de350b;
				}
			}

			.badge__text {
				font-weight: normal;
			}

			.badge__close {
				font-weight: normal;
				border: 0;
			}
		}
	}

	&__controls {
		display: flex;
		flex-direction: column;
		margin: 0 1rem;

		&__row {
			margin: 0.5rem 0rem;

			.playlist__unavailable {
				margin: 0;
			}

			.song-table__filter,
			.dropdown__placeholder {
				font-size: 0.9rem;
			}

			.search-bar {
				&__container {
					width: 100%;
					font-size: 0.9rem;
					border: 1px solid black;
					min-height: 25px;
				}

				&__input {
					&__image {
						height: 15px;
						width: 15px;
					}

					& input[type='search'] {
						font-size: 0.9rem;
					}
				}
			}
		}

		&__action:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.data-table__header-row {
		border-bottom: none;
	}

	&__table {
		&__header {
			font-weight: 500;
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
			&--borderless {
				border-bottom: 0 !important;
			}
		}

		&__row {
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
			height: 72px;
			padding-top: 3px;
			display: table-row;

			&--dragging {
				border: 1px dashed $black;
				background-color: $white;
				box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1) !important;
				height: 72px;
				display: table;
			}

			&--borderless {
				border-bottom: 0 !important;
			}

			&--disabled {
				background-color: rgba(196, 196, 196, 0.1);
				td:not(:last-child):not(:first-child):not(.enabled-always) {
					opacity: 0.5;
				}

				td.enabled-always {
					textarea::placeholder {
						color: $gray-4;
					}
				}

				&:hover {
					background-color: rgba(196, 196, 196, 0.1) !important;
				}
			}

			&--clone {
				display: table-row;
				transform: none !important;

				~ tr {
					transform: none !important;
				}
				~ [data-rbd-placeholder-context-id] {
					display: none !important;
				}
			}

			td {
				padding-right: 0.5rem;
			}

			&__play {
				display: flex;
				justify-content: center;
				align-items: center;

				&__icon {
					cursor: pointer;
					visibility: hidden;
					height: 15px;
					width: 15px;

					&--visible {
						visibility: visible;
					}

					& svg {
						height: 15px;
						width: 15px;
					}
				}
			}

			&__drag-handle {
				visibility: hidden;

				&:hover {
					cursor: grab;
				}

				& svg {
					height: 15px;
					width: 15px;
				}
			}

			&:hover {
				background-color: $gray-6;
				.song-table__table__row__play__icon {
					visibility: visible;
				}
				.song-table__table__row__drag-handle {
					visibility: visible;
				}
			}

			&__grey {
				color: rgba(0, 0, 0, 0.5);
			}

			&__actions {
				display: flex;
				justify-content: flex-start;
			}

			&__song-name {
				display: flex;
				align-items: center;
			}

			&__explicit {
				color: red;
				border: 1px solid red;
				margin-left: 0.75rem;
				padding: 0 2px;
				font-weight: $font-weight-medium;
				font-size: 0.5rem;
				opacity: 0.8;
				line-height: 0.75rem;
			}
		}
	}
}
