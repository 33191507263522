.notifications {
	&__icon {
		height: 32px;
		width: 32px;
	}

	&__panel {
		visibility: hidden;
		min-width: 339px;
		background-color: white;
		color: black;
		position: absolute;
		z-index: 2;
		cursor: default;
		right: 0;
		top: 38px;
		max-height: calc(100vh - 100px);
		overflow: hidden;

		&--visible {
			visibility: visible;
		}
	}

	&__header {
		background: #f1f1f1;
		font-size: 22px;
		line-height: 28px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 60px;
	}

	&__list {
		margin-top: 65px; // notifications panel header height
		overflow-y: auto;
		max-height: calc(100vh - 100px - 65px);
	}

	&__list-inner {
		width: 100%;
		position: relative;
	}

	&__item {
		min-height: 55px;
		padding: 1rem;
		text-align: left;
		list-style: none;
		display: flex;
		align-items: center;

		&__clickable {
			&:hover {
				background-color: $light-gray;
				cursor: pointer;
			}
		}
	}
}
