.select-container {
	.select__control {
		border-color: #000000;
		box-shadow: none;
		&:hover {
			border-color: #000000;
		}
	}

	.select__value-container {
		max-height: 250px;
		overflow: auto;
	}

	.select__menu-list {
		padding-top: 0;
	}

	.select__option {
		&--is-focused {
			background-color: $gray-3;
		}
	}

	.select__multi-value {
		color: white;
		border-radius: 15px;
		background-color: black;
		padding: 0 5px 0 5px;

		&__label {
			color: white;
		}

		&__remove {
			&:hover {
				background-color: black;
				border-radius: 0 15px 15px 0;
			}
		}
	}

	.select__clear-indicator {
		&:hover {
			color: #de350b;
		}
	}
}
