.playlist {
	&__container {
		margin-top: -2rem;
	}

	&__back {
		color: black;
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.02em;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}

		&__btn {
			margin-right: 10px;
			padding-bottom: 4px;
		}
	}

	&__header {
		margin-top: 1.1rem;
		display: flex;

		&__badge {
			margin-top: -0.5rem;
			margin-left: 1.5rem;
		}
	}

	&__sub-text {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 0.7rem;

		&--no-margin {
			margin-bottom: 0;
		}
	}

	&__target-duration {
		&__text {
			margin-right: 5px;
		}

		&__dropdown-text {
			font-size: 18px !important;
			line-height: 24px !important;
		}

		&__dropdown-control {
			height: 18px !important;
		}

		&__arrow {
			margin-top: 7px !important;
		}

		&--invalid {
			margin-left: 0.5rem;
			color: blue;
		}
	}

	&__notes {
		display: flex;

		&__textarea {
			margin-left: 8px;
			width: 100%;

			.notes__tooltip__panel {
				height: 300px;
			}

			.notes__tooltip__panel__textarea {
				max-height: 150px;
			}
		}
	}

	&__show-id {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 0.7rem;

		&__text {
			font-weight: lighter;
			margin-left: 0.5rem;

			&--editable {
				cursor: pointer;
			}
		}
	}

	&__course-genre {
		margin-top: 1rem;
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 28px;
	}

	&__description {
		margin-top: 1rem;
		max-width: 581px;
	}

	&__unavailable {
		background-color: $gray-3;
		margin-top: 1.5rem;
		padding: 1rem;
		display: flex;

		ul {
			padding-left: 1.2rem;
			margin-bottom: 0px;
		}
	}

	&__empty {
		margin-top: toRem(100px);

		&__container {
			max-width: 450px;
		}

		&__header {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 33px;
			text-align: center;
		}

		&__sub {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #757575;
			margin-top: 1rem;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 0.1rem;
	}

	&__play-btn {
		height: 37px;
		cursor: pointer;

		&.unplayable {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	&__switch {
		&__label {
			margin-right: 10px;
			margin-top: 2px;
		}
	}

	&__metadata {
		font-size: 0.95rem;
		&__value {
			font-weight: $font-weight-bold;
		}
	}

	&__action_button {
		display: flex;
		padding: 0 1.5rem;
		align-items: center;
		min-height: 50px;
		height: unset;
		max-height: 100%;
		margin: 0 -15px;
		svg {
			fill: currentColor;
			width: 1.75rem;
			height: 1.75rem;
		}
	}

	&__submission {
		text-align: right;

		&__header {
			font-weight: bold;
		}

		&__item {
			list-style: none;
			margin-top: 6px;
			margin-bottom: 6px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&--invalid {
				color: red;
			}

			&--valid {
				color: green;
			}
		}
	}
}
