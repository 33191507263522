.artist-filter {
	&__character {
		margin-right: 1rem;
		font-weight: bold;
		cursor: pointer;

		&--selected {
			border-bottom: 2px solid black;
			font-weight: bolder;
		}
	}
}
