.team-grid {
	width: 100%;
	&__header-cell {
		font-size: 20px;
		line-height: 23px;
		text-align: left;
	}

	&__header-row {
		border-bottom: 1px solid black;
		height: 72px;
	}

	&__row {
		border-bottom: 1px solid black;
		height: 72px;

		&:hover {
			background-color: $light-gray;
			color: $black;
		}
	}

	&__grid-btn {
		border: 0;
		padding: 0;
		background-color: transparent;
		font-weight: bold;
		margin-left: $margin-md;
	}
}
