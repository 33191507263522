.data-table {
	width: 100%;

	&--fixed {
		table-layout: fixed;
	}

	&--centered {
		.data-table__header-cell {
			text-align: center;
		}
		.data-table__cell {
			text-align: center;
		}
	}

	&__header-cell {
		font-size: 20px;
		line-height: 23px;
		text-align: left;
	}

	&__header-row,
	&__footer-row,
	&__footer-summary {
		border-bottom: 1px solid $black;
		height: 72px;
	}

	&__row {
		border-bottom: 1px solid $black;
		height: 72px;

		&:hover {
			background-color: $light-gray;
			color: $black;
		}
	}
}
