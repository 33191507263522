.action-menu {
	position: relative;

	&__panel {
		display: none;
		position: absolute;
		z-index: 3;
		bottom: 0;
		right: 2.5rem;
		cursor: default;
		color: $brand-black;
		font-size: 0.9rem;
		background-color: $white;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
		width: 250px;
		max-height: 325px;

		&--visible {
			display: block;
			overflow-y: auto;
		}

		&__divider {
			border-bottom: 1px solid $gray-2;
			margin-right: 1.25rem;
			margin-left: 1.25rem;
		}

		&__metadata {
			text-align: left;
			padding: 0 1.75rem 0.5rem;
			cursor: auto;
			font-size: 0.85rem;

			&__header {
				font-weight: $font-weight-bold;
				opacity: 0.8;
				font-size: 0.9rem;
			}

			> div {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		&__item {
			height: 45px;
			text-align: left;
			display: flex;
			align-items: center;
			position: relative;

			&__label {
				padding-left: 1.75rem;
				padding-right: 1.75rem;
			}

			&:hover,
			&--active {
				background-color: $gray-6;
				cursor: pointer;
			}
		}

		&__collections {
			position: relative;
			justify-content: space-between;

			&__chevron {
				height: 15px;
				width: 12px;
				margin-right: 1.25rem;
			}

			&__panel {
				display: none;
				min-width: 220px;
				background-color: $white;
				color: $brand-black;
				color: black;
				position: absolute;
				z-index: 3;
				right: 290px;
				top: -30px;
				cursor: default;
				max-height: 50px;
				box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
				padding-bottom: 0.5rem;
				padding-top: 0.5rem;
				font-size: 0.9rem;

				&--visible {
					display: block;
					max-height: 275px;
					overflow-y: auto;
				}

				&__new-collection {
					height: 50px;
					text-align: left;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__overlay {
		z-index: 2;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
