.playlists {
	&__filters {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		&__playlist_type {
			margin-left: 0.5rem;
			margin-top: 3px;
		}
	}

	&__sort-control {
		cursor: pointer;
	}

	& .inverse {
		transform: rotate(180deg);
	}

	&__grid {
		margin-top: $margin-md;
	}
}
