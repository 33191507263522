.tooltip-container {
	position: relative;
	display: inline-block;

	&__tooltip {
		visibility: hidden;
		position: absolute;
		width: 140px;
		background-color: transparent;
		color: #fff;
		font-size: 12px;
		text-align: center;
		padding: 5px 0;
		border-radius: 6px;
		z-index: 1;
		opacity: 0;
		transition: opacity 0.6s ease, background-color 0.6s ease;
		bottom: 105%;
		left: 50%;
		margin-bottom: 5px;
		margin-left: -70px; /* Center the tooltip */

		:hover > & {
			visibility: visible;
			opacity: 100;
			background-color: black;
		}
	}
}
