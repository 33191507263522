.team {
	&__staff-data {
		margin: 0;
		font-size: 18px;
	}
	&__filters {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	&__sort-control {
		cursor: pointer;
	}

	& .inverse {
		transform: rotate(180deg);
	}

	&__grid {
		margin-top: $margin-md;
	}
}
