.licesing-dashboard {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& button {
			margin-top: 0;
			padding: 0 1.5rem;
			text-align: center;
			align-items: center;
			display: flex;
			svg {
				fill: currentColor;
				width: 1.25rem;
				height: 1.25rem;
				margin-right: 0.25rem;
			}
		}
	}

	&__loader {
		margin-top: 8rem;
	}

	&__filter {
		display: inline-flex;
		align-items: baseline;
		margin: 1rem 0;
		background-color: $gray-3;
		width: 100%;
		padding: 0.5rem;

		&__label {
			margin-right: 0.5rem;
			font-weight: $font-weight-bold;
		}

		&__dropdown {
			align-self: flex-start;
			margin-top: 8px;
			margin-right: 1.5rem;
			padding-left: 0.4rem;
			padding-right: 0.4rem;
			border: 1px solid black;
			min-width: 100px;

			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				background: transparent url(../../images/arrow-down.svg) no-repeat;
				background-position-x: 99%;
				background-position-y: 8px;
				padding-right: 1.2rem;

				option {
					text-align: center;
				}
			}

			&__menu {
				right: 0;
				margin-top: 0.25rem;
				margin-right: -0.5rem;
				max-height: 450px;
				overflow-y: auto;
				width: inherit !important;
			}
		}
	}

	&__extra-filter {
		display: flex;
		align-items: center;

		&--wrappable {
			flex-wrap: wrap;
			margin-top: -1rem;
			align-items: flex-end;
		}

		&__dropdown {
			border: 1px solid black;
			margin-left: 0.8rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			min-width: 100px;

			&__menu {
				right: 0;
				margin-top: 0.25rem;
				margin-right: auto;
				max-height: 450px;
				overflow-y: auto;
				width: inherit !important;
			}
		}
	}

	&__extra-filter-controls {
		display: flex;
		flex-direction: column;

		&__row {
			margin: 0.5rem 0rem;

			.playlist__unavailable {
				margin: 0;
			}

			.song-table__filter,
			.dropdown__placeholder {
				font-size: 0.9rem;
			}

			.search-bar {
				&__container {
					width: 100%;
					font-size: 0.9rem;
					border: 1px solid black;
					min-height: 25px;
				}

				&__input {
					&__image {
						height: 15px;
						width: 15px;
					}

					& input[type='search'] {
						font-size: 0.9rem;
					}
				}
			}
		}

		&__action {
			margin-left: 0.4rem;
		}

		& button {
			margin: 0;
			margin-left: 0.8rem;
			height: 1.8rem;
			min-width: unset;
			width: 70px;
		}

		&__action:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	&__report {
		&__table {
			&__header {
				display: flex;
				align-items: center;
				cursor: pointer;
				justify-content: space-between;

				&__icon {
					display: inline-flex;
					margin-right: 20px;
					align-items: center;

					svg {
						fill: $dark-gray;
						width: 1rem;
						height: 1rem;
					}
					&--inverse {
						transform: rotate(180deg);
					}
				}
			}
			.data-table__header-cell {
				font-size: 0.9rem;
				font-weight: $font-weight-bold;
			}

			.data-table__cell {
				padding: 18px 5px;
				font-size: 0.8rem;
				word-break: break-word;
				word-wrap: break-word;
			}
		}
	}
}
