.loader {
	&__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.75;
		flex-direction: column;
		text-align: center;
	}

	width: 90px;
	height: 50px;
	position: relative;

	&__bar {
		display: inline-block;
		position: absolute;
		width: 16px;
		bottom: 0;
		background: $brand-black;
		min-height: 10px;
		animation-name: musicWave;
		animation-iteration-count: infinite;
		animation-duration: 0.99s;

		&:nth-child(1) {
			left: 0px;
			animation-delay: 0.2s;
		}
		&:nth-child(2) {
			left: 18px;
			animation-delay: 0.4s;
		}
		&:nth-child(3) {
			left: 36px;
			animation-delay: 0.6s;
		}
		&:nth-child(4) {
			left: 54px;
			animation-delay: 0.8s;
		}
		&:nth-child(5) {
			left: 72px;
			animation-delay: 0.99s;
		}
	}
}

.loader-alt {
	border: 6px solid #f3f3f3; /* Light grey */
	border-top: 6px solid black;
	border-bottom: 6px solid black;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes musicWave {
	0% {
		transform: scaleY(1);
		transform-origin: bottom;
		background: $brand-black;
	}
	30% {
		transform: scaleY(2);
		transform-origin: bottom;
		background: $brand-black;
	}
	60% {
		transform: scaleY(2.5);
		transform-origin: bottom;
		background: $brand-black;
	}
	80% {
		transform: scaleY(2);
		transform-origin: bottom;
		background: $brand-black;
	}
	100% {
		transform: scaleY(1);
		transform-origin: bottom;
		background: $dark-gray;
	}
}
