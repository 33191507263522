.folders {
	&__header {
		border-bottom: 1px black solid;
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		padding-bottom: 6px;
		position: relative;

		&__see-all {
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			position: absolute;
			bottom: 6px;
			right: 0;
			cursor: pointer;
		}
	}

	&__list {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding-top: 1rem;
	}
}

.folder {
	&__card {
		min-height: 300px;
		min-width: 300px;
		width: 23.5%;
		margin-bottom: 1rem;
		margin-right: 1rem;
		background-size: cover;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		overflow: hidden;
		padding-right: 0.5rem;

		&:hover {
			cursor: pointer;
			opacity: 0.95;
		}

		&__title {
			color: white;
			font-family: Roboto, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			line-height: 32px;
			display: flex;
			align-items: center;
			width: 100%;
			margin-left: 1.5rem;

			&--overflowing {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 95%;
			}
		}

		&__details {
			color: white;
			padding-bottom: 1rem;
			margin-left: 1.5rem;
			margin-right: 1rem;
		}
	}
}
