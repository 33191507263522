.pagination {
	color: rgba(0, 0, 0, 0.5);

	&:hover {
		color: rgba(0, 0, 0, 0.5);
	}

	&__selected-page {
		font-weight: 700;
	}
}

.page-link {
	cursor: pointer;
	border: none;
}
