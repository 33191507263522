.collection {
	margin-bottom: 2rem;
	width: 100%;

	&__header {
		display: flex;
		justify-content: space-between;

		&--carousel {
			border-bottom: 1px solid black;
			margin-bottom: 2rem;
		}

		&__text {
			font-family: Roboto, sans-serif;
			color: #000000;
		}

		&__see-all {
			text-decoration: underline;
			cursor: pointer;
			&--carousel {
				text-decoration: none;
			}
		}
	}

	&__carousel {
		display: flex;
		max-width: 100%;
		align-items: flex-start;
	}

	&__children {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;

		&--carousel {
			display: block;

			// Overwrite carousel aligning to place arrow at middle of images
			.slick-slider {
				display: block;
				width: 100%;
				min-width: 97%;
			}

			.slick-track {
				display: flex;
				overflow: hidden;
				min-width: 100%;
			}
		}

		&__arrow {
			display: flex;
			margin-top: calc(110px - 43px);
			height: 60px;
			width: 18px;
			cursor: pointer;

			&--large {
				margin-top: calc(150px - 35px);
				height: 80px;
				width: 25px;

				&.collection__children__arrow {
					&--left {
						margin-right: 0.5rem;
					}

					&--right {
						margin-right: 0.25rem;
					}
				}
			}

			&--left {
				margin-right: 1rem;
			}

			&--right {
				margin-right: 1.25rem;
			}
		}
	}

	&__card {
		max-width: 100%;
		height: 100%;
		margin-bottom: 1rem;
		min-height: 110px;
		margin-right: 2rem;

		& a {
			text-decoration: none;
			color: inherit;
			display: flex;
			width: 100%;
		}

		&--carousel {
			padding-top: 0;
			margin-right: 0;
			width: 100%;
		}

		&__clickable {
			cursor: pointer;
			padding-top: 1rem;

			&--white {
				&:hover {
					background-color: $light-gray;
				}
			}

			&--black {
				&:hover {
					background-color: $gray-4;
				}
			}
		}

		&__image {
			&--default {
				background: url(../../../images/card-bg-1.svg) center;
				background-size: cover;
			}

			&__carousel {
				height: 220px;
				max-width: 220px;
				width: 100%;

				&__clickable {
					cursor: pointer;
					&:hover {
						background-color: $light-gray;
					}
				}
			}

			height: 100px;
			width: 100px;
		}

		&__metadata {
			font-family: Roboto, sans-serif;
			max-width: 220px;
			word-break: break-word;

			> div {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			&--white {
				color: white;
			}

			&--black {
				color: black;
			}

			&__subtitle {
				color: rgba(0, 0, 0, 0.5);
			}

			&__clickable {
				cursor: pointer;
				&:hover {
					background-color: $light-gray;
				}
			}

			&__status {
				margin-top: 0.5rem;
				padding: 0 0.5rem;
				width: fit-content;
				font-size: 0.9rem;

				&--black {
					border: 1px solid black;
					color: black;
				}

				&--white {
					border: 1px solid white;
					color: white;
				}
			}
		}
	}
}
