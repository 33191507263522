.songs-validation {
	&__results {
		&__table {
			.data-table__header-cell {
				font-size: 0.9rem;
				font-weight: $font-weight-bold;
				text-align: center;
			}

			.data-table__cell {
				padding: 15px 0;
				text-align: center;
				div {
					text-align: justify;
					margin-left: 25px;
				}
			}
		}
	}
}
