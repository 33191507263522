.show-playlists {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& button {
			margin-top: 0;
			padding: 0 1.5rem;
			text-align: center;
			align-items: center;
			display: flex;
			svg {
				fill: currentColor;
				width: 1.25rem;
				height: 1.25rem;
				margin-right: 0.25rem;
			}
		}
	}

	&__table {
		width: 100%;

		&__header {
			&__row {
				border-bottom: 1px solid black;
				height: 72px;
			}

			&__cell {
				font-size: 20px;
				line-height: 23px;
				text-align: left;
				padding: 1rem;
			}
		}

		&__row {
			border-bottom: 1px solid black;
			height: 72px;

			&--link {
				cursor: pointer;
			}

			&__cell {
				padding-left: 1rem;
			}
		}
	}
}
