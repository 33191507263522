.songcard {
	min-width: 203px;
	margin-bottom: 1.5rem;

	&:hover {
		background-color: $gray;
	}

	&__image {
		margin-right: 1rem;
		height: 65px;
		min-width: 65px;
	}

	&__title {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 16px;
	}

	&__artist {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		margin-top: 3px;
	}

	&__bpm {
		font-family: Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
	}

	&__favorite {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}
