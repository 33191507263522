.badge {
	display: flex;
	justify-content: space-between;
	align-self: center;
	height: 25px;
	color: $brand-black;
	background-color: $brand-yellow;
	border-radius: 36px;
	padding: 2px 12px;
	min-width: 25px;
	width: fit-content;

	&__text {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: $font-weight-bold;
		font-size: 1rem;
	}

	&__close {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 0.45rem;
		padding-left: 0.35rem;
		border-left: 1px solid $brand-black;
		font-size: 1rem;
		line-height: 1.25rem;
		height: 100%;
		min-width: 10px;
		&::before {
			content: 'x';
		}
		&:hover {
			font-weight: $font-weight-bold;
			color: #de350b;
			cursor: pointer;
		}
	}
}
