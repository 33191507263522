.songslist {
	&__song__dropdown__menu {
		position: absolute;
		bottom: 0;
		left: 2.5rem;
		margin-left: 0;
		margin-top: 0.25rem;
		margin-right: -0.5rem;
		width: max-content !important;
		max-height: 250px;
		overflow-y: scroll;
	}

	&__filter {
		&__btn {
			color: black;
			background-color: white;
			border: none;
		}

		&__container {
			float: right;
			margin-bottom: -1rem;

			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				background: transparent url(../../images/arrow-down.svg) no-repeat;
				background-position-x: 99%;
				background-position-y: 8px;
				padding-right: 1.2rem;

				option {
					text-align: center;
				}
			}
		}
	}

	&__table {
		&__row {
			box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1) !important;
			border-radius: 4px;
			min-height: 145px;
			@media only screen and (max-width: 1280px) {
				font-size: 85%;
			}
		}

		&__play {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-repeat: no-repeat;
			height: 145px;
			width: 145px;
			background-size: cover;
			margin-right: $margin-sm;
		}
	}

	&__song {
		&__name {
			display: flex;
			align-items: center;
			> b {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

		&__data-label {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&__data-note {
			font-size: 0.9rem;
			@media only screen and (max-width: 1280px) {
				font-size: 85%;
				opacity: 0.8;
			}
		}

		&--explicit {
			color: red;
			border: 1px solid red;
			padding: 0 2px;
			margin-left: 0.5rem;
			font-weight: $font-weight-medium;
			font-size: 0.5rem;
			line-height: 0.75rem;
		}

		&__btn {
			background: none;
			border: none;
			width: 23.5px;
			padding: 0;
			cursor: pointer;
			height: 23.5px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0.5rem;

			img {
				width: fit-content;
				height: inherit;
			}

			&--remove {
				display: flex;
				align-items: center;
				height: 19px;
				margin: 0.15rem 0.25rem 0rem 0.25rem;
			}
		}

		&__btn-label {
			font-size: 0.75rem;
			text-align: center;
		}

		&__alert {
			margin-right: 7px;
		}
	}

	&__tooltip {
		position: relative;
		display: inline-block;

		&__panel {
			visibility: hidden;
			min-width: 220px;
			background-color: white;
			color: black;
			position: absolute;
			z-index: 2;
			bottom: 0;
			right: 2.5rem;
			cursor: default;
			max-height: 350px;
			overflow: auto;

			&__item {
				height: 65px;
				padding: 1rem;
				text-align: left;
				display: flex;
				align-items: center;

				&:hover {
					background-color: $light-gray;
				}

				&__action {
					height: 55px;
					border-bottom: 1px solid $gray-2;
				}

				&:last-child:first-child {
					border-bottom: none !important;
				}
			}

			&__details {
				font-weight: $base-font-weight;
				font-size: 0.75rem;
				color: $gray-4;
				@media only screen and (max-width: 1280px) {
					font-size: 85%;
				}
			}

			&--visible {
				visibility: visible;
				max-height: 250px;
				overflow-y: auto;
			}
		}

		&__overlay {
			z-index: 1;
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: default;
		}
	}

	&__song-data {
		margin-left: $margin-sm;
	}

	&__cleared-timestamp {
		font-size: 0.8rem;
		opacity: 0.8;
		position: absolute;
		bottom: 44px;
		text-align: right;
		right: 20px;

		&--cc {
			bottom: 30px;
			right: 20px;
		}
		@media only screen and (max-width: 1280px) {
			font-size: 85%;
		}
	}

	&__row {
		&__zone-transition-io {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			&__field {
				display: flex;
				align-items: center;
				height: 2rem;

				&--zone-transition {
					justify-content: center;
				}

				&--io {
					justify-content: space-between;
				}
			}
			@media only screen and (max-width: 1280px) {
				.Dropdown-placeholder {
					font-size: 100%;
				}
			}
		}

		&__notes {
			margin-bottom: 8px;
		}
	}
}
